export const marvelData = [
    '/marvel/Cap.jpg',
    '/marvel/Collossus.jpg',
    '/marvel/Cyclops.jpg',
    '/marvel/Hulk.jpg',
    '/marvel/Ironman.jpg',
    '/marvel/Nightcrawler.jpg',
    '/marvel/Phoenix.jpg',
    '/marvel/Rogue.jpg',
    '/marvel/Spidey.jpg',
    '/marvel/Storm.jpg',
    '/marvel/Thor.jpg',
    '/marvel/Wolverine.jpg',
];